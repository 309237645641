body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

#root {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}


h1 {
  margin: 0;
}
